<template>
  <div v-frag>
    <v-dialog v-model='datePickerr' max-width='500px'>
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-4'>
          <v-icon
            class='mr-4 white--text' color='success' large left
          >mdi-alert
          </v-icon
          >
          Schedule My Time
        </v-card-title>
        <v-card-subtitle>
          <b>Select a time:</b>
          <v-menu
            ref='menu5'
            v-model='menu5'
            :close-on-content-click='false'
            max-width='290px'
            min-width='auto'
            offset-y
            transition='scale-transition'
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-text-field
                v-model='datePic'
                dense
                outlined
                v-bind='attrs'
                v-on='on'
              ></v-text-field>
            </template>
            <v-date-picker
              v-model='datePic'
              no-title
              @input='menu5 = false'
            ></v-date-picker>
          </v-menu>
        </v-card-subtitle>

        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            outlined
            @click='closeRequestModal'
          >
            Cancel
          </v-btn
          >
          <v-btn
            :disabled='!datePic'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='requestReg(datePic)'
          >
            Submit
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if='getObjLength && !employee.vuid && !employee.scheduleVuid' class='mt-6 ml-7'>
      <v-btn
        class='text-capitalize font-weight-bold px-14 py-6 text--white'
        color='error'
        outlined
        width='90%'
        @click='showDate()'
      >
        You Are Not Registered To Vote. Click Here To Schedule Your Registration
      </v-btn>
    </v-row>
    <v-row class='px-6 d-flex flex-row align-start'>
      <v-col class='pa-4 d-flex flex-column align-stretch' height='100%' md='5'>
        <v-card class='pa-6' height='575px'>
          <v-row class='d-flex flex-row align-center justify-center mt-6'>
            <v-avatar
              v-if="image === ''"
              class='primary elevation-3'
              size='100'
            >
            </v-avatar>
            <v-avatar v-if="image != ''" class='elevation-3' size='100'>
              <img :src='image'/>
            </v-avatar>
            <v-card-title
            >{{ employee.firstName }} {{ employee.lastName }}
            </v-card-title
            >
          </v-row>
          <v-row class='d-flex align-center justify-center mt-16 mx-6'>
            <v-btn
              v-if='enableEdit'
              class='text-capitalize font-weight-bold px-14 py-6'
              color='primary'
              outlined
              width='100%'
              @click='toggleDialog()'
            >
              Edit Profile
            </v-btn>
            <div v-if='!enableEdit' class='filler'></div>
          </v-row>
<!--          <v-row-->
<!--            class='-->
<!--              d-flex-->
<!--              flex-row-->
<!--              align-center-->
<!--              justify-space-between-->
<!--              px-6-->
<!--              mt-10-->
<!--            '-->
<!--          >-->
<!--            <p class='font-weight-bold'>Home Phone</p>-->
<!--            <p>{{ employee.homePhone }}</p>-->
<!--          </v-row>-->
<!--          <v-row-->
<!--            class='d-flex flex-row align-center justify-space-between px-6'-->
<!--          >-->
<!--            <p class='font-weight-bold'>Work Phone</p>-->
<!--            <p>{{ employee.workPhone }}</p>-->
<!--          </v-row>-->
          <v-row
            class='d-flex flex-row align-center justify-space-between px-6'
          >
            <p class='font-weight-bold'>Mobile Phone</p>
            <p>{{ employee.mobilePhone }}</p>
          </v-row>
          <v-row
            class='d-flex flex-row align-center justify-space-between px-6'
          >
            <p class='font-weight-bold'>Primary Email</p>
            <p>{{ employee.email }}</p>
          </v-row>
<!--          <v-row-->
<!--            class='d-flex flex-row align-center justify-space-between px-6'-->
<!--          >-->
<!--            <p class='font-weight-bold'>Secondary Email</p>-->
<!--            <p>{{ employee.secondaryEmail }}</p>-->
<!--          </v-row>-->
          <v-row
            class='d-flex flex-row align-center justify-space-between px-6'
          >
            <p class='font-weight-bold'>Address</p>
            <p>{{ employee.address }}</p>
          </v-row>
          <v-row
            class='d-flex flex-row align-center justify-space-between px-6'
          >
            <p class='font-weight-bold'>City</p>
            <p>{{ employee.city }}</p>
          </v-row>
          <v-row
            class='d-flex flex-row align-center justify-space-between px-6'
          >
            <p class='font-weight-bold'>State</p>
            <p>{{ employee.state }}</p>
          </v-row>
          <v-row
            class='d-flex flex-row align-center justify-space-between px-6'
          >
            <p class='font-weight-bold'>Zip Code</p>
            <p>{{ employee.zipCode }}</p>
          </v-row>
          <v-row
            class='d-flex flex-row align-center justify-space-between px-6'
          >
            <p class='font-weight-bold'>County</p>
            <p>{{ employee.county.text }}</p>
          </v-row>
        </v-card>
      </v-col>
      <v-col class='pa-4' md='7'>
        <v-card class='pa-4 pb-11'>
          <v-card-title
            class='d-flex flex-row align-center justify-center pt-6 pb-4'
          >
            Time Supporting Our Community
          </v-card-title>
          <v-row v-if="isModuleActive('volunteering')" class='mb-6'>
            <v-col>
              <div class='d-flex flex-column align-center justify-center'>
                <p class='font-weight-bold'>Total Annual Hours:
                  <span class='primary--text'>{{ daysOff.annualHours }}</span></p>
                <p class='font-weight-bold'>Total Remaining Hours:
                  <span class='primary--text'>{{ daysOff.availableHours }}</span></p>
              </div>
            </v-col>
          </v-row>
          <table class='elevation-3' width='100%'>
            <v-simple-table class='rounded-pill'>
              <thead>
              <tr>
                <th class='text-left'></th>
                <th class='text-left'>Days</th>
                <th class='text-left'>Hours</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="isModuleActive('voting')">
                <td class='py-6 font-weight-bold'>Total Voting Time Off</td>
                <td>{{ daysOff.votingDaysOff }}</td>
                <td>{{ daysOff.votingHoursOff }}</td>
              </tr>
              <tr v-if="isModuleActive('volunteering')">
                <td class='py-6 font-weight-bold'>
                  Total Volunteering Time Off
                </td>
                <td>{{ daysOff.volunteeringDays }}</td>
                <td>{{ daysOff.volunteeringHours }}</td>
              </tr>
              <tr v-if="isModuleActive('volunteering')">
                <td class='py-6 font-weight-bold'>
                  Total Non-Partner Volunteering
                </td>
                <td>{{ daysOff.nonPartnerVolunteeringDays }}</td>
                <td>{{ daysOff.nonPartnerVolunteeringHours }}</td>
              </tr>
              <tr class='grey white--text'>
                <td class='py-6 font-weight-bold'>TOTAL</td>
                <td>{{ daysOff.daysTotal }}</td>
                <td>{{ daysOff.hoursTotal }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </table>

          <v-card
            v-if="isModuleActive('donations')"
            class='
              mt-10
              pt-6
              pb-2
              px-6
              d-flex
              flex-row
              align-center
              justify-space-between
            '
            flat
          >
            <h3 class='ml-4 font-weight-bold'>Total Donations</h3>
            <h2 class='mr-10 success--text font-weight-bold'>
              ${{ employee.totalDonations }}
            </h2>
          </v-card>
        </v-card>

        <v-card
          class='
            mt-8
            pt-6
            pb-2
            d-flex
            flex-row
            align-center
            justify-space-around
          '
        >
          <p v-show='enableEdit' class='font-weight-bold'>VUID</p>
          <p v-show='enableEdit' class='mr-6'>{{ employee.vuid }}</p>
          <p class='font-weight-bold'>Birth Year</p>
          <p class='mr-6'>{{ employee.birthDate }}</p>
        </v-card>
      </v-col>
    </v-row>
    <Alert/>
    <v-card v-if="employee.vuid && !employee.inelegible && isModuleActive('voting')" class='mx-6 px-6 mt-6 elevation-1'>
      <v-row align='center' class='px-4' justify='space-between'>
        <v-col>
          <h3>Your Voter Guide:</h3>
          <p>
            <v-select
              v-model='selectedElectionId'
              :items='elections'
              dense
              item-text='description'
              item-value='id'
              label='Election'
              outlined
              required
              @change='onElectionSelect'
            ></v-select>
          </p>
        </v-col>
        <v-col class='d-flex flex-column justify-center align-end'>
          <v-btn
            class='text-capitalize font-weight-bold'
            color='primary'
            @click='goToVotersGuide()'
          >
            View Voter Guide
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-col v-if="isModuleActive('voting')" class='mb-7'>
      <div v-if='user.id_rol === 4' v-frag>
        <card-task :election='selectedElection'/>
      </div>
    </v-col>
    <v-row class='mx-6 my-6'>
      <v-card class='pa-6' width='100%'>
        <v-btn-toggle
          v-model='toggleExclusive'
          class='d-flex flex-row justify-center'
          color='primary accent-4'
          group
          mandatory
          @change='populateTable(toggleExclusive)'
        >
          <v-btn v-if="isModuleActive('volunteering')" text value='volunteering'>VOLUNTEERING REQUEST</v-btn>
          <v-btn v-if="isModuleActive('volunteering')" text value='previous'>PREVIOUS VOLUNTEERING</v-btn>
          <v-btn v-if="isModuleActive('voting')" text value='voting'>VOTING REQUEST</v-btn>
          <v-btn v-if="isModuleActive('donations')" text value='donation'>DONATION HISTORY</v-btn>
          <v-btn text value='subscription'>ACTIVE SUBSCRIPTIONS</v-btn>
        </v-btn-toggle>

        <v-data-table
          :headers='tableHeaders'
          :items='tableItems'
          :loading='loadingRequests'
          flat
          hide-default-footer
        >
          <template v-slot:[`item.approvedBy`]='{ item }'>
            <span
              v-if="item.approvedBy == 'Pending'"
              class='text-decoration-underline error--text'
            >
              {{ item.approvedBy }}
            </span>
            <span
              v-if="item.approvedBy != 'Pending'"
              class='text-decoration-underline'
            >
              {{ item.approvedBy.firstName }}
            </span>
          </template>
          <template v-slot:[`item.verified`]='{ item }'>
            <span v-if='item.verified'> Yes </span>
            <span v-if='!item.verified'> - </span>
          </template>

          <template v-slot:[`item.donationAmount`]='{ item }'>
            <span> $ {{ item.donationAmount }} </span>
          </template>
          <template v-slot:[`item.totalAmount`]='{ item }'>
            <span> {{ formatMoney(item.totalAmount) }} </span>
          </template>
          <template v-slot:[`item.commission`]='{ item }'>
            <span> {{ formatMoney(item.commission) }} </span>
          </template>
          <template v-slot:[`item.actions`]='{ item }'>
            <v-btn
              class='font-weight-bold white--text'
              color='red'
              x-small
              @click='showDialogSubsCancel(item)'
            >
              <v-icon small> mdi-cancel</v-icon>
              Cancel
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-row>

    <v-row class='mx-6 mb-4'>
      <v-card class='pa-6' width='100%'>
        <v-data-table
          :expanded.sync='expanded'
          :headers='organizationHeaders'
          :items='subordinates'
          :single-expand='singleExpand'
          class='my-table pt-16'
          flat
          item-key='id'
          show-expand
          @item-expanded='populateTreeview'
        >
          <template v-slot:top>
            <v-toolbar class='mb-10' flat>
              <v-row class='pb-16'>
                <v-col>
                  <v-toolbar-title
                    class='font-weight-bold mb-4'
                  >My Organization Status
                  </v-toolbar-title
                  >
                  <h4 class='mb-1'>{{ employee.fullName }}</h4>
                  <p>
                    Reports to:
                    <span
                      v-if='superior === null'
                      class='primary--text font-weight-bold'
                    >
                      -
                    </span>
                    <span
                      v-if='superior != null && superior.id != employee.id'
                      class='
                        primary--text
                        font-weight-bold
                        text-decoration-underline
                      '
                    >
                      {{ superior.firstName }} {{ superior.lastName }}
                    </span>
                    <span
                      v-if='superior.id === employee.id'
                      class='primary--text font-weight-bold'
                    >
                      Themselves
                    </span>
                  </p>
                </v-col>
                <v-col class='d-flex flex-column'>
                  <!-- <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search Employee"
                    single-line
                    dense
                    outlined
                    hide-details
                    class="mr-4 mb-6"
                  >
                  </v-text-field> -->
                  <v-select
                    v-model='selectedElectionId'
                    :items='elections'
                    dense
                    item-text='description'
                    item-value='id'
                    label='Select a voting campaign'
                    outlined
                    required
                    @change='onElectionSelect'
                  ></v-select>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.reg`]='{ item }'>
            <span class='text-capitalize'>
              {{ item.reg }}
            </span>
          </template>
          <template v-slot:[`item.votedDate`]='{ item }'>
            <span class='text-capitalize'> {{ item.votedDate }} </span>
          </template>
          <template v-slot:[`item.empReg`]='{ item }'>
            <span v-if='item.empReg == 100' class='success--text'>
              {{ item.empReg }} %
            </span>
            <span
              v-if='item.empReg < 100 && item.empReg >= 50'
              class='warning--text'
            >
              {{ item.empReg }} %
            </span>
            <span v-if='item.empReg < 50' class='error--text'>
              {{ item.empReg }} %
            </span>
          </template>
          <template v-slot:[`item.empVot`]='{ item }'>
            <span v-if='item.empVot == 100' class='success--text'>
              {{ item.empVot }} %
            </span>
            <span
              v-if='item.empVot < 99 && item.empVot >= 50'
              class='warning--text'
            >
              {{ item.empVot }} %
            </span>
            <span v-if='item.empVot < 50' class='error--text'>
              {{ item.empVot }} %
            </span>
          </template>

          <template v-slot:expanded-item='{ headers }'>
            <td :colspan='headers.length' class='ma-0 pl-16'>
              <v-treeview
                ref='treeview'
                :items='treeview'
                class='pl-4'
                expand-icon=''
                item-key='id'
                open-all
                open-on-click
                transition
              >
                <template v-slot:label='{ item }'>
                  <v-row class='d-flex flex-row flex-nowrap'>
                    <v-col class='d-flex flex-row justify-start'>
                      <span class='pl-12'>
                        {{ item.name }}
                      </span>
                    </v-col>
                    <v-col class='d-flex flex-row justify-center'>
                      <span class='ml-14'>
                        {{ item.jobTitle }}
                      </span>
                    </v-col>
                    <v-col
                      class='d-flex flex-row justify-start text-capitalize'
                    >
                      <span class='ml-16 pl-2'>
                        {{ item.reg }}
                      </span>
                    </v-col>
                    <v-col class='d-flex flex-row justify-start pl-0'>
                      <span class='ml-12'>
                        {{ item.dayScheduledToVote }}
                      </span>
                    </v-col>
                    <v-col class='d-flex flex-row justify-start' md='2'>
                      <span class='ml-12'> {{ item.votedDate }} </span>
                    </v-col>
                    <v-col class='d-flex flex-row justify-start pl-0' md='1'>
                      <span v-if='item.registered' class='ml-2 success--text'>
                        Yes
                      </span>
                      <span v-if='!item.registered' class='ml-2 error--text'>
                        No
                      </span>
                    </v-col>
                    <v-col class='d-flex flex-row justify-center' md='1'>
                      <span v-if='item.voted' class='pr-4 success--text'>
                        Yes
                      </span>
                      <span v-if='!item.voted' class='pr-4 error--text'>
                        No
                      </span>
                    </v-col>
                  </v-row>
                </template>
              </v-treeview>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
    <v-dialog
      v-model='dialog'
      max-width='1000px'
      transition='dialog-bottom-transition'
    >
      <v-form ref='form' v-model='valid'>
        <v-card>
          <v-card-title>
            <span
              class='
                headline
                font-weight-bold
                text-transform-capitalize
                ml-4
                mt-6
              '
            >{{ employee.firstName }} {{ employee.lastName }}</span
            >
          </v-card-title>
          <v-card-text class='py-2 px-4'>
            <v-container>
              <v-row class='mb-n10' md='4' sm='6'>
                <v-col>
                  <p class='font-weight-bold'>First Name</p>
                  <v-text-field
                    v-model='editedItem.firstName'
                    :rules="[(v) => !!v || 'Field is required']"
                    dense
                    outlined
                    placeholder='i.e John'
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <p class='font-weight-bold'>Middle Name</p>
                  <v-text-field
                    v-model='editedItem.middleName'
                    dense
                    outlined
                    placeholder='i.e Phill'
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class='mb-n10' md='4' sm='6'>
                <v-col>
                  <p class='font-weight-bold'>Last Name</p>
                  <v-text-field
                    v-model='editedItem.lastName'
                    :rules="[(v) => !!v || 'Field is required']"
                    dense
                    outlined
                    placeholder='i.e Williams'
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <p class='font-weight-bold'>Birth Year</p>
                  <year-picker v-model='editedItem.birthDate'/>
                </v-col>
              </v-row>
              <v-row class='mb-n10' md='4' sm='6'>
                <v-col>
                  <p class='font-weight-bold'>Home Phone</p>
                  <v-text-field
                    v-model='editedItem.homePhone'
                    :rules='phoneRules'
                    dense
                    outlined
                    placeholder='i.e 2123456789'
                  ></v-text-field>
                </v-col>
                <v-col>
                  <p class='font-weight-bold'>Work Phone</p>
                  <v-text-field
                    v-model='editedItem.workPhone'
                    :rules='phoneRules'
                    dense
                    outlined
                    placeholder='i.e 2123456789'
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class='mb-n10' md='4' sm='6'>
                <v-col>
                  <p class='font-weight-bold'>Mobile Phone</p>
                  <v-text-field
                    v-model='editedItem.mobilePhone'
                    :rules='phoneRules'
                    dense
                    outlined
                    placeholder='i.e 2123456789'
                  ></v-text-field>
                </v-col>
                <v-col>
                  <p class='font-weight-bold'>Address</p>
                  <v-text-field
                    id='addressInput'
                    ref='addressInput'
                    v-model='editedItem.address'
                    :rules='addressRules'
                    dense
                    outlined
                    placeholder='i.e Lincoln Square 123'
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class='mb-n10' md='4' sm='6'>
                <v-col>
                  <p class='font-weight-bold'>City</p>
                  <v-text-field
                    id='cityInput'
                    ref='cityInput'
                    v-model='editedItem.city'
                    :rules='cityRules'
                    dense
                    outlined
                    placeholder='i.e Chicago'
                  ></v-text-field>
                </v-col>
                <v-col>
                  <p class='font-weight-bold'>State</p>
                  <v-text-field
                    id='stateInput'
                    ref='stateInput'
                    v-model='editedItem.state'
                    :rules='stateRules'
                    dense
                    outlined
                    placeholder='i.e Illinois'
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class='mb-n10' md='4' sm='6'>
                <v-col>
                  <p class='font-weight-bold'>Zip Code</p>
                  <v-text-field
                    id='zipCodeInput'
                    ref='zipCodeInput'
                    v-model='editedItem.zipCode'
                    :rules='zipCodeRules'
                    dense
                    outlined
                    placeholder='i.e 9851123'
                  ></v-text-field>
                </v-col>
                <v-col>
                  <p class='font-weight-bold'>VUID</p>
                  <v-text-field
                    v-model='editedItem.vuid'
                    dense
                    outlined
                    placeholder='i.e 9851123'
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class='mb-n10' md='4' sm='6'>
                <v-col>
                  <p class='font-weight-bold'>Primary Email</p>
                  <v-text-field
                    v-model='editedItem.email'
                    :rules="[(v) => !!v || 'Field is required']"
                    dense
                    outlined
                    placeholder='i.e john@mydomain.com'
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <p class='font-weight-bold'>Secondary Email</p>
                  <v-text-field
                    v-model='editedItem.secondaryEmail'
                    dense
                    outlined
                    placeholder='i.e john@mydomain.com'
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class='mb-n10' md='4' sm='6'>
                <v-col md='6' sm='6'>
                  <p class='font-weight-bold'>Reports To</p>
                  <v-autocomplete
                    v-model='editedItem.reportsTo'
                    :items='employeesToReport'
                    dense
                    item-value='id'
                    label='Who do you report to?'
                    outlined
                  >
                    <template v-slot:selection='data'>
                      {{ data.item.fullName }}
                    </template>
                    <template v-slot:item='data'>
                      {{ data.item.fullName }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row md='4' sm='6'>
                <v-col>
                  <p class='font-weight-bold'>Add a profile picture</p>
                  <p class='font-weight-light'>JPG, PNG, file*</p>
                  <v-row>
                    <v-col md='3'>
                      <v-avatar
                        v-if="image === ''"
                        class='primary elevation-3'
                        size='150'
                      >
                      </v-avatar>
                      <v-avatar
                        v-if="image != ''"
                        class='elevation-3'
                        size='150'
                      >
                        <img :src='image'/>
                      </v-avatar>
                    </v-col>
                    <v-col class='d-flex flex-column'>
                      <v-file-input
                        accept='image/png, image/jpeg, image/bmp'
                        chips
                        class='d-flex justify-end mt-16'
                        dense
                        outlined
                        placeholder='Add a file or drop it here'
                        prepend-icon=''
                        prepend-inner-icon='mdi-paperclip'
                        show-size
                        truncate-length='50'
                        @change='selectFile'
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class='mb-6' md='4' sm='6'>
                <v-col>
                  <span>
                    <p class='font-weight-bold'>Notification Settings</p>
                  </span>
                  <span
                    class='
                      d-flex
                      flex-row
                      align-center
                      justify-start
                      mt-n5
                      ml-4
                    '
                  >
                    <p class='font-weight-lighs mr-4'>SMS</p>
                    <v-switch
                      v-model='editedItem.smsNotificacion'
                      class='mb-3 ml-1'
                      primary
                    >
                    </v-switch>
                  </span>
                  <span>
                    <p class='font-weight-bold'>Account Settings</p>
                  </span>
                  <span
                    class='d-flex flex-column align-start justify-start ml-4'
                  >
                    <v-btn
                      v-if='accountActive'
                      class='pt-4'
                      text
                      @click="toggleStatusDialog('suspend')"
                    >
                      <p class='font-weight-light'>Suspend Account</p>
                    </v-btn>
                    <v-btn
                      v-if='!accountActive'
                      class='pt-4'
                      text
                      @click="toggleStatusDialog('reincorporate')"
                    >
                      <p class='font-weight-light'>Reincorporate Account</p>
                    </v-btn>
                  </span>
                </v-col>
              </v-row>
              <v-dialog v-model='dialogSuspend' max-width='500px'>
                <v-card class='d-flex flex-column align-start pa-2'>
                  <v-card-title class='text-center mb-5'>
                    <v-icon
                      class='mr-4 white--text' color='warning' large left
                    >mdi-alert
                    </v-icon
                    >
                    Suspend Account
                  </v-card-title>
                  <v-card-subtitle>
                    <b
                    >You are about to suspend your account, are you sure you
                      want to proceed?</b
                    >
                  </v-card-subtitle>
                  <v-card-actions class='align-self-center'>
                    <v-spacer></v-spacer>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      outlined
                      @click="toggleStatusDialog('suspend')"
                    >
                      Cancel
                    </v-btn
                    >
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      @click="changeAccountStatus('suspend')"
                    >
                      Confirm
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model='dialogReincorporate' max-width='500px'>
                <v-card class='d-flex flex-column align-start pa-2'>
                  <v-card-title class='text-center mb-5'>
                    <v-icon
                      class='mr-4 white--text' color='success' large left
                    >mdi-alert
                    </v-icon
                    >
                    Reincoporate Account
                  </v-card-title>
                  <v-card-subtitle>
                    <b
                    >You are about to reactivate your account, are you sure
                      you want to proceed?</b
                    >
                  </v-card-subtitle>

                  <v-card-actions class='align-self-center'>
                    <v-spacer></v-spacer>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      outlined
                      @click="toggleStatusDialog('reincorporate')"
                    >
                      Cancel
                    </v-btn
                    >
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      @click="changeAccountStatus('reincorporate')"
                    >
                      Confirm
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-alert
                v-model='showSuspendAlert'
                class='rounded-xl'
                color='warning'
                dark
                dense
                dismissible
                icon='mdi-information-outline'
                prominent
                transition='scale-transition'
                width='100%'
              >
                <h3 class='mt-2'>Account Suspended</h3>
                <p>
                  You can re activate your account clicking the reincorporate
                  button in your profile edit form.
                </p>
              </v-alert>
              <v-alert
                v-model='showReincorporateAlert'
                class='rounded-xl'
                color='success'
                dark
                dense
                dismissible
                icon='mdi-information-outline'
                prominent
                transition='scale-transition'
                width='100%'
              >
                <h3 class='mt-2'>Account Activated</h3>
                <p>Your account was succesfully reactivated.</p>
              </v-alert>
            </v-container>
          </v-card-text>

          <v-card-actions class='d-flex direction-row justify-center'>
            <v-btn
              class='px-14 ma-4 font-weight-bold'
              color='primary'
              large
              outlined
              @click='toggleDialog()'
            >
              Cancel
            </v-btn>
            <v-btn
              class='px-14 ma-4 font-weight-bold white--text'
              color='primary'
              large
              @click='save'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-model='dialogCrop'
      max-width='500px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <clipper-fixed
            ref='clipper'
            :round='true'
            :src='imagePreview'
          ></clipper-fixed>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class='px-14 mx-4 font-weight-bold'
            color='primary'
            large
            outlined
            @click='cancelCrop'
          >
            Cancel
          </v-btn>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='cropImage'
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='dialogSubsCancel' max-width='500px'>
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon
            class='mr-4 white--text' color='warning' large left
          >mdi-alert
          </v-icon
          >
          Cancel Subscription
        </v-card-title>
        <v-card-subtitle>
          <b
          >You are about to cancel your subscription, are you sure you want to
            proceed?</b
          >
        </v-card-subtitle>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            outlined
            @click='dialogSubsCancel = false'
          >
            Cancel
          </v-btn
          >
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='cancelSubscription()'
          >
            Confirm
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model='dialogAddress' max-width='500px'>
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon
            class='mr-4 white--text' color='warning' large left
          >mdi-alert
          </v-icon
          >
          Warning!
        </v-card-title>
        <v-card-subtitle>
          <b
          >The entered address may not belong to a real address, do you want
            to continue with the operation?</b
          >
        </v-card-subtitle>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            outlined
            @click='dialogAddress = false'
          >
            Cancel
          </v-btn
          >
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='confirmEditProfile()'
          >
            Confirm
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import employeeProfile from '../../services/employeeProfile'
import googleMapsService from '../../services/googleMaps'
import employee from '../../services/employee'
import donationsService from '../../services/donations'
import Alert from '../../components/Alert'
import CardTask from '../campaing/components/CardTask'
import memberService from '../../services/member'
import { mapActions, mapGetters } from 'vuex'
import YearPicker from '@/components/year-picker'

export default {
  components: {
    YearPicker,
    CardTask,
    Alert
  },
  data() {
    return {
      accountActive: Boolean,
      employeeId: null,
      dialogSuspend: false,
      dialogReincorporate: false,
      dialogSubsCancel: false,
      dialogAddress: false,
      showSuspendAlert: false,
      showReincorporateAlert: false,
      toggleExclusive: undefined,
      employeesToReport: [],
      loadingRequests: true,
      tableItems: [],
      fileUntoched: true,
      enableEdit: true,
      tableHeaders: [],
      organizationItems: [],
      dialog: false,
      image: '',
      expanded: [],
      singleExpand: true,
      search: '',
      selectedElectionId: null,
      selectedElection: {},
      selected: [],
      elections: [],
      children: [],
      valid: true,
      volunteeringRequest: [],
      previousRequest: [],
      votingRequest: [],
      donationHistory: [],
      subscriptions: [],
      date: new Date().toISOString()
        .substr(0, 10),
      menu: false,
      datePickerr: false,
      menu5: false,
      datePic: '',
      employees: [],
      subordinates: [],
      open: [],
      active: [],
      treeview: [],
      employee: {},
      daysOff: {},
      superior: {},
      editedItem: {},
      addressRules: [(v) => !!v || 'Address is required'],
      cityRules: [(v) => !!v || 'City is required'],
      stateRules: [(v) => !!v || 'State is required'],
      zipCodeRules: [(v) => !!v || 'Zip code is required'],
      phoneRules: [
        v => /^\d+$/.test(v) || 'Value must be a number',
        v => v.length <= 10 || 'Value must be equal to 10 characters',
        v => v.length >= 10 || 'Value must be equal to 10 characters'
      ],
      coordinates: {
        latitude: null,
        longitude: null
      },
      subsToCancel: {},
      volunteeringHeaders: [
        {
          text: 'Charity',
          align: 'center',
          sortable: true,
          value: 'charity.name'
        },
        {
          text: 'Date',
          align: 'center',
          sortable: true,
          value: 'date'
        },
        {
          text: 'Hours',
          align: 'center',
          sortable: true,
          value: 'hours'
        },
        {
          text: 'Acknowledged By',
          align: 'center',
          sortable: true,
          value: 'approvedBy'
        },
        {
          text: 'Verified By Charity',
          align: 'center',
          sortable: true,
          value: 'verified'
        },
        {
          text: 'Leave',
          align: 'center',
          sortable: true,
          value: 'leave'
        }
      ],
      votingHeaders: [
        {
          text: 'Campaing',
          align: 'start',
          sortable: true,
          value: 'election.description'
        },
        {
          text: 'Request Date',
          align: 'start',
          sortable: true,
          value: 'requestDate'
        },
        {
          text: 'Voting Date',
          align: 'start',
          sortable: true,
          value: 'voteDate'
        },
        {
          text: 'Acknowledged By',
          align: 'start',
          sortable: true,
          value: 'approvedBy.fullName'
        },
        {
          text: 'Leave',
          align: 'start',
          sortable: true,
          value: 'leave'
        }
      ],
      donationHeaders: [
        {
          text: 'Charity',
          align: 'start',
          sortable: true,
          value: 'charityName'
        },
        {
          text: 'Need',
          align: 'start',
          sortable: true,
          value: 'need'
        },
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date'
        },
        {
          text: 'Amount',
          align: 'start',
          sortable: true,
          value: 'donationAmount'
        }
      ],
      subscriptionsHeaders: [
        {
          text: 'Charity',
          align: 'start',
          sortable: false,
          value: 'charityName',
          width: '25%'
        },
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'date',
          width: '20%'
        },
        {
          text: 'MCBA Commission',
          align: 'start',
          sortable: false,
          value: 'commission',
          width: '20%'
        },
        {
          text: 'Total Amount',
          align: 'start',
          sortable: false,
          value: 'totalAmount',
          width: '25%'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],
      organizationHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '20%'
        },
        {
          text: 'Job Title',
          sortable: false,
          value: 'jobTitle'
        },
        {
          text: 'Reg',
          sortable: false,
          value: 'reg'
        },
        {
          text: 'Day Scheduled to Vote',
          sortable: false,
          align: 'center',
          value: 'dayScheduledToVote'
        },
        {
          text: 'Day Voted',
          sortable: false,
          align: 'center',
          value: 'votedDate'
        },
        {
          text: 'Emp. Reg',
          sortable: false,
          align: 'center',
          value: 'empReg'
        },
        {
          text: 'Emp. Vot',
          align: 'center',
          sortable: false,
          value: 'empVot'
        }
      ],
      dialogCrop: false,
      fileType: '',
      imagePreview: '',
      cardTaskKey: 0,
      memberHour: {
        total_hours: 0
      }
    }
  },
  computed: {
    ...mapGetters(['getModules', 'election']),
    getObjLength() {
      return Object.keys(this.employee).length
    },
    user() {
      return this.$store.getters.user
    }
  },
  async created() {
    this.$store.getters.user.id
    this.$store.getters.employee.id
    this.enableEdit = !this.$route.params.id
    await this.getEmployeeProfile()
    await this.getElections()
    await this.getEmployeesToReportTo()
    await this.initialize()
    console.log(this.employee, 'employee')
  },
  methods: {
    ...mapActions(['addModules']),
    async initialize() {
      await this.addModules(this.employee.memberId)
      this.tableHeaders = this.volunteeringHeaders
      this.toggleExclusive = 'volunteering'
      await this.populateTable(this.toggleExclusive)
      await this.getMemberYear()
    },
    isModuleActive(module) {
      return this.getModules.includes(module)
    },
    async getMemberYear() {
      const res = await memberService.getMemberYear(this.employee.memberId)
      if (res.data) {
        this.memberHour = res.data
      }
    },
    goToVotersGuide() {
      this.$router.push({ name: 'VotersGuideEmployee', params: { employeeId: this.employee.id } })
      this.$store.dispatch('election', this.selectedElection)
    },
    async onElectionSelect() {
      this.subordinates = []
      const employeeId = await this.getEmployeeId()
      this.subordinates = await this.getSubordinates(this.selectedElectionId, employeeId)
      const electionSelected = this.elections.filter(el => el.id === this.selectedElectionId)
      this.selectedElection = electionSelected[0]
      await this.$store.dispatch('election', this.selectedElection)
    },
    async getEmployeeId() {
      try {
        if (this.$route.params.id) {
          const idEmployee = this.$store.getters.employee.id

          return idEmployee
        } else {
          const userId = this.$store.getters.user.id
          const employee = await employeeProfile.getEmployeeId({
            userId: userId
          })
          const idEmployee = employee.employeeId

          return idEmployee
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getEmployeeProfile() {
      const employeeId = await this.getEmployeeId()

      try {
        const employee = await employeeProfile.getEmployeeProfile(employeeId)
        this.employee = employee
        this.imagePreview = employee.profilePicture
        this.image = employee.profilePicture
        if (this.employee.active) this.accountActive = true
        else this.accountActive = false

        this.daysOff = this.employee.daysOff
        this.superior = this.employee.reportsTo
      } catch (err) {
        console.log(err)
      }
    },
    async getEmployeesToReportTo() {
      const employeeId = await this.getEmployeeId()
      if (this.employee.memberId != null) {
        try {
          this.employeesToReport = await employee.getEmployeesToReport(this.employee.memberId)
          this.employeesToReport = this.employeesToReport.filter((employee) => {
            return employee.id !== employeeId
          })
        } catch (err) {
          console.log(err)
        }
      }
    },
    async getElections() {
      try {
        const employeeId = await this.getEmployeeId()
        this.elections = await employeeProfile.getElections(employeeId)
        if (Object.keys(this.election).length === 0 || this.elections.every(el => this.election.id !== el.id)) {
          this.selectedElection = this.elections[0]
          this.selectedElectionId = this.selectedElection.id
        } else {
          this.selectedElection = this.election
          this.selectedElectionId = this.election.id
        }
        this.subordinates = await this.getSubordinates(this.selectedElectionId, employeeId)
      } catch (err) {
        console.log(err)
      }
    },

    async getSubordinates(electionId, employeeId) {
      try {
        const subordinateObject = await employeeProfile.getSubordinates({
          electionId: electionId,
          employeeId: employeeId
        })
        return this.filterResponse(subordinateObject.subordinates, employeeId)
      } catch (err) {
        console.log(err)
      }
    },
    async getSubordinatesView(electionId, employeeId) {
      try {
        const subordinateObject = await employeeProfile.getSubordinatesView({
          electionId: electionId,
          employeeId: employeeId
        })
        // return this.filterResponse(subordinateObject, employeeId);

        return subordinateObject
      } catch (err) {
        console.log(err)
      }
    },
    async populateTreeview({ item }) {
      this.treeview = []
      const hasChildren = item.name.includes('(')
      if (hasChildren) {
        const election = this.selectedElectionId

        const directSubordinates = await this.getSubordinatesView(
          election,
          item.id
        )
        this.treeview = directSubordinates
      }
    },
    toggleDialog() {
      this.dialog = !this.dialog
      this.dialogAddress = false
      if (this.dialog) {
        this.editedItem = Object.assign({}, this.employee)
      } else this.editedItem = {}
    },
    filterResponse(array, id) {
      const filter = array.filter((subordinate) => {
        return subordinate.employeeId != id
      })
      return filter
    },
    async populateTable(toggleExclusive) {
      const employeeId = await this.getEmployeeId()

      switch (toggleExclusive) {
        case 'volunteering':
          this.tableItems = []
          this.tableHeaders = this.volunteeringHeaders
          this.loadingRequests = true
          await this.getVolunteeringRequest(employeeId)
          this.tableItems = this.volunteeringRequest
          break

        case 'previous':
          this.tableItems = []
          this.tableHeaders = this.volunteeringHeaders
          this.loadingRequests = true
          await this.getPreviousRequest(employeeId)
          this.tableItems = this.previousRequest
          break

        case 'voting':
          this.tableItems = []
          this.tableHeaders = this.votingHeaders
          this.loadingRequests = true
          await this.getVotingRequest(employeeId)
          this.tableItems = this.votingRequest

          break

        case 'donation':
          this.tableItems = []
          this.tableHeaders = this.donationHeaders
          this.loadingRequests = true
          await this.getDonationHistory(employeeId)

          this.tableItems = this.donationHistory
          break

        case 'subscription':
          this.tableItems = []
          this.tableHeaders = this.subscriptionsHeaders
          this.loadingRequests = true
          await this.getSubscriptions(employeeId)
          this.tableItems = this.subscriptions
          break

        default:
          break
      }
    },
    async getVolunteeringRequest(id) {
      try {
        this.volunteeringRequest =
          await employeeProfile.getVolunteeringRequests(id)
        this.loadingRequests = false
      } catch (err) {
        console.log(err)
      }
    },
    async getPreviousRequest(id) {
      try {
        this.previousRequest = await employeeProfile.getPreviousRequests(id)
        this.loadingRequests = false
      } catch (err) {
        console.log(err)
      }
    },
    async getVotingRequest(id) {
      try {
        this.votingRequest = await employeeProfile.getVotingRequests(id)
        this.loadingRequests = false
      } catch (err) {
        console.log(err)
      }
    },
    async getDonationHistory(id) {
      try {
        this.donationHistory = await employeeProfile.getDonationHistory(id)
        this.loadingRequests = false
      } catch (err) {
        console.log(err)
      }
    },
    async getSubscriptions(id) {
      const commission = this.$store.getters.commission
      try {
        const res = await employeeProfile.getSubscriptions(id)
        this.subscriptions = res.map((subs) => {
          subs.totalAmount = subs.amount / 100
          subs.commission = (commission * subs.totalAmount) / 100
          return subs
        })
        this.loadingRequests = false
      } catch (err) {
        console.log(err)
      }
    },
    async getEmployees() {
      try {
        this.employees = await employee.getEmployees()
      } catch (err) {
        console.log(err)
      }
    },
    confirmEditProfile() {
      this.editEmployee()
      this.toggleDialog()
    },
    async editEmployee() {
      try {
        if (this.editedItem.hierarchy.id)
          this.editedItem.hierarchy = this.editedItem.hierarchy.id
        if (this.editedItem.reportsTo.id)
          this.editedItem.reportsTo = this.editedItem.reportsTo.id
        await employeeProfile.editEmployee({
          id: this.editedItem.id,
          firstName: this.editedItem.firstName,
          middleName: this.editedItem.middleName,
          lastName: this.editedItem.lastName,
          userName: this.editedItem.userName,
          email: this.editedItem.email,
          secondaryEmail: this.editedItem.secondaryEmail,
          homePhone: this.editedItem.homePhone,
          workPhone: this.editedItem.workPhone,
          mobilePhone: this.editedItem.mobilePhone,
          address: this.editedItem.address,
          city: this.editedItem.city,
          state: this.editedItem.state,
          latitude: this.coordinates.latitude,
          longitude: this.coordinates.longitude,
          vuid: this.editedItem.vuid,
          zipCode: this.editedItem.zipCode,
          birthDate: this.editedItem.birthDate,
          hierarchy: this.editedItem.hierarchy,
          reportsTo: this.editedItem.reportsTo,
          profilePictureObject: this.editedItem.profilePicture,
          emailNotification: this.editedItem.emailNotification,
          smsNotificacion: this.editedItem.smsNotificacion,
          fileType: this.fileType
        })
        this.$store.dispatch('image')
        this.getEmployeeProfile()
      } catch (err) {
        console.log(err)
      }
    },
    showDialogSubsCancel(item) {
      this.subsToCancel = item
      this.dialogSubsCancel = true
    },
    showDate() {
      this.datePickerr = true
    },
    closeRequestModal() {
      this.datePickerr = false
    },
    async requestReg(item) {
      const employeeId = await this.getEmployeeId()
      let state = false
      try {
        this.datePickerr = await employee.addRegistreRequest({ employeeId, item, state })
      } catch (err) {
        console.log(err)
      }
      this.employee.scheduleVuid = true
      this.closeRequestModal()
    },
    async cancelSubscription() {
      const item = this.subsToCancel
      const data = {
        id: item.id
      }
      const res = await donationsService.cancelSubscription(data)
      if (res.success) {
        this.subscriptions = this.subscriptions.filter((subs) => {
          if (subs.id != item.id) {
            return subs
          }
        })
        this.tableItems = this.subscriptions
        this.$store.dispatch('alert', {
          show: true,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
      } else {
        this.$store.dispatch('alert', {
          show: true,
          color: 'red',
          title: 'Something went wrong!!',
          message: 'The subscription could not be canceled, please try again.',
          response: true
        })
      }
      this.dialogSubsCancel = false
      this.subsToCancel = {}
    },
    selectFile(file) {
      if (file) {
        this.imagePreview = URL.createObjectURL(file)
        this.fileType = file.type
        // this.employee.profilePicture = this.image;
        // this.editedItem.profilePicture = file;
        this.dialogCrop = true
      }
    },
    async enableDisableEmployee() {
      const employeeId = await this.getEmployeeId()

      try {
        await employee.enableDisableEmployee({
          id: employeeId
        })
        this.getEmployees()
      } catch (err) {
        console.log(err)
      }
    },
    toggleStatusDialog(dialog) {
      if (dialog == 'suspend') {
        this.dialogSuspend = !this.dialogSuspend
      }
      if (dialog == 'reincorporate') {
        this.dialogReincorporate = !this.dialogReincorporate
      }
    },
    async changeAccountStatus(changeTo) {
      if (changeTo == 'suspend') {
        await this.enableDisableEmployee();
        (this.accountActive = false), this.toggleStatusDialog('suspend')
        this.showAlertMsg('suspend')
      }
      if (changeTo == 'reincorporate') {
        await this.enableDisableEmployee();
        (this.accountActive = true), this.toggleStatusDialog('reincorporate')
        this.showAlertMsg('reincorporate')
      }
    },
    hideAlertMsg() {
      window.setTimeout(() => {
        this.showReincorporateAlert = false
        this.showSuspendAlert = false
      }, 5000)
    },

    showAlertMsg(type) {
      switch (type) {
        case 'suspend':
          this.showSuspendAlert = true
          break
        case 'reincorporate':
          this.showReincorporateAlert = true
          break
      }
      this.hideAlertMsg()
    },

    async save() {
      const valid = this.$refs.form.validate()
      const validAddress = await this.validateAddress()
      if (valid) {
        if (validAddress) {
          this.confirmEditProfile()
        } else {
          this.dialogAddress = true
        }
      }
    },
    async validateAddress() {
      const data = {
        address: this.$refs.addressInput.value,
        state: this.$refs.stateInput.value,
        city: this.$refs.cityInput.value,
        postal_code: this.$refs.zipCodeInput.value,
        country: 'USA'
      }
      const res = await googleMapsService.validateAddress(data)
      if (res.results && res.results.length > 0) {
        this.coordinates = {
          latitude: res.results[0].geometry.location.lat,
          longitude: res.results[0].geometry.location.lng
        }
        return true
      }
      return false
    },
    formatMoney: function (value) {
      if (typeof value == 'number') {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
    },
    cropImage() {
      this.$refs.clipper.clip()
        .toBlob((blob) => {
          this.editedItem.profilePicture = blob
          this.image = URL.createObjectURL(blob)
          this.imagePreview = URL.createObjectURL(blob)
          this.cancelCrop()
        })
    },
    cancelCrop() {
      this.dialogCrop = false
    }
  }
}
</script>
<style lang='scss'>
.v-treeview-node__root .v-treeview-node__level {
  width: 10px;
}
.v-treeview-node__root button {
  width: 0;
}
.filler {
  width: 100%;
  height: 53px;
}
</style>

<style lang='scss' scoped>
.my-table.v-data-table :deep(th) {
  font-size: 16px !important;
  font-weight: bold;
  color: #212121 !important;
}
</style>
